//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import GetReport from '~/components/GetReport.vue';
import constant from '~/const';
import Filters from './StatisticsTradesFilters.vue';

import tableConfig from './tableConfig';
import Extra from './StatisticsTradesExtra.vue';

export default {
  components: { Filters, GetReport, Extra },

  data() {
    return {
      tableConfig,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('currencies', ['toCurrencyFormat', 'getP2PCurrencyByTitle']),

    ...mapState('statisticsTrades', ['data']),
    ...mapGetters('statisticsTrades', ['reportData']),

    reportOperations: () => constant.reports.P2PAY_REPORT_TYPE_IDS.TRANSACTIONS_STATISTIC,

    areAllRowsEmpty() {
      return Object.values(this.data).every(({ rows }) => !rows?.length);
    },

    getTableData() {
      let res = [];
      if (!this.areAllRowsEmpty) {
        const {
          turnover,
          averageReceipt,
          tradesNumber,
          traderProfit,
          merchantFee,
          serviceProfit,
        } = this.data;
        const filtered = Object.entries({
          turnover,
          averageReceipt,
          tradesNumber,
          traderProfit,
          merchantFee,
          serviceProfit,
        })
          .filter(([key]) => key !== 'result')
          .map(([key, value], index) => ({
            name: key,
            data: value,
            id: index,
            isRowHasHideExtra: key === 'turnover' || key === 'averageReceipt',
          }));
        res = filtered;
      }
      return res;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('statisticsTrades', {
      loadDataAction: 'loadData',
    }),

    getRowData(row, type) {
      // Если имя строки равно 'tradesNumber'
      // tradesNumber единственный, в котором нужно вернуть число без валюты
      if (row.name === 'tradesNumber') {
        // Возвращаем значение type, округленное до 12 значащих цифр, и пустую строку в качестве валюты
        // Регулярка форматирует числа с плавающей запятой и убирает лишние нули
        // 124.000000 = 124
        // 124.453...(>12) = 124.453...(12)
        return [Number(row?.data?.rows[0]?.[type]).toPrecision(12).replace(/(?:\.0+|(\.\d+?)0+)$/, '$1'), ''];
      }
      // В остальных случаях возвращаем значение type и код валюты
      return [Number(row?.data?.rows[0]?.[type]).toPrecision(12).replace(/(?:\.0+|(\.\d+?)0+)$/, '$1'), row?.data?.rows[0]?.currencyCode];
    },

    async applyFilters() {
      try {
        this.setGeneralLoader(true);
        await this.loadDataAction();
        this.setGeneralLoader(false);
      } catch (error) {
        this.setErrorNotification(error.message);
      }
    },
  },
};
