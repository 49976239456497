export default [
  {
    width: 12,
    header: {
      type: 'text',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'caption',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'PayIn trades',
    },
    cell: {
      type: 'slot',
      name: 'payinTrades',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'PayOut trades',
    },
    cell: {
      type: 'slot',
      name: 'payoutTrades',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Total',
    },
    cell: {
      type: 'slot',
      name: 'total',
    },
  },
];
