export default [
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currencyCode,
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'PayIn trades',
    },
    cell: {
      type: 'text',
      value: (e) => e.payIn,
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'PayOut trades',
    },
    cell: {
      type: 'text',
      value: (e) => e.payOut,
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Total',
    },
    cell: {
      type: 'text',
      value: (e) => e.total,
    },
  },
];
