var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',[_c('div',{staticClass:"d-flex"},[_c('Filters',{on:{"update":_vm.applyFilters}}),_c('GetReport',{staticClass:"ml-4",attrs:{"type":_vm.reportOperations,"command":_vm.reportData}})],1)]),_c('v-row',[_c('v-col',{staticClass:"flex-grow-0 section__col"},[_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"data":_vm.getTableData,"config":_vm.tableConfig,"is-loading":_vm.generalLoader,"fixed-header":"","show-extra":"","disable-all-show-extra":"","height":"720px"},scopedSlots:_vm._u([{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"loader":_vm.generalLoader,"type":row.name,"data":row}})]}},{key:"caption",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.$utils.capitalizer(row.name)))+" ")]}},{key:"payinTrades",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getRowData(row, 'payIn')[0])+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.getRowData(row, 'payIn')[1]))])]}},{key:"payoutTrades",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getRowData(row, 'payOut')[0])+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.getRowData(row, 'payOut')[1]))])]}},{key:"total",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getRowData(row, 'total')[0])+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.getRowData(row, 'total')[1]))])]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }