//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import tableConfig from './configs/config_traderProfit';

export default {
  props: {
    content: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      tableConfig,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
  },
};
