//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import merchantFee from './extra/extra_merchantFee.vue';
import traderProfit from './extra/extra_traderProfit.vue';
import serviceProfit from './extra/extra_serviceProfit.vue';
import tradesNumber from './extra/extra_tradesNumber.vue';

export default {
  components: {
    merchantFee,
    traderProfit,
    serviceProfit,
    tradesNumber,
  },

  props: {
    loader: { type: Boolean, default: false },
    type: { type: String, default: '' },
    data: { type: Object, default: () => ({}) },
  },
};
