//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        fromDate: 0,
        toDate: 0,
      },
      selectedTimeRange: null,
      constant,
    };
  },

  computed: {
    ...mapState('statisticsTrades', ['filterLists']),
    ...mapGetters('currencies', ['getP2PCurrencyByTitle']),
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  async mounted() {
    this.setDateByCurrent();
  },

  methods: {
    ...mapActions('statisticsTrades', ['setFilters']),

    setDateByCurrent() {
      const from = new Date();
      from.setDate(from.getDate() - 1);
      from.setHours(13, 0, 0, 0);
      const fromTimestamp = moment(from).unix();

      const to = new Date();
      to.setHours(13, 0, 0, 0);
      const toTimestamp = moment(to).unix();

      this.filters = {
        ...this.filters,
        fromDate: fromTimestamp,
        toDate: toTimestamp,
      };
    },

    updateTimeRange() {
      const now = moment();
      let fromDate = now.clone();

      switch (this.selectedTimeRange) {
        case '10min':
          fromDate.subtract(10, 'minutes');
          break;
        case '1hour':
          fromDate.subtract(1, 'hours');
          break;
        case '1day':
          fromDate.subtract(1, 'days');
          break;
        default:
          fromDate = now.clone().set({
            hour: 13,
            minute: 0,
          });
          now.add(1, 'days').set({
            hour: 13,
            minute: 0,
          });
      }

      this.filters.fromDate = fromDate.unix();
      this.filters.toDate = now.unix();
    },

    clearQuickFilter() {
      this.setDateByCurrent();
      this.selectedTimeRange = null;
    },
  },
};
